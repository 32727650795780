import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Ubicazioni/';

class UbicazioniService {
    getUbicazioni(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GetUbicazioni", null,
        successFn,
        failFn
        );
    }

    getUbicazione(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idUbicazione", id);

        UtilsRequest.get(API_URL + "GetUbicazione", parametri,
        successFn,
        failFn
        );
    }
    
    getTemplateUbicazione(successFn, failFn){
        const parametri = new URLSearchParams();

        UtilsRequest.get(API_URL + "GetTemplateUbicazione", null,
        successFn,
        failFn
        );
    }

    createUbicazione(ubicazione, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateUbicazione", ubicazione, 
        successFn,
        failFn
        );
    }

    updateUbicazione(ubicazione, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateUbicazione", ubicazione, 
        successFn,
        failFn
        );
    }

    deleteUbicazione(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        
        UtilsRequest.delete(API_URL + "DeleteUbicazione", parametri, 
        successFn,
        failFn
        );
    }
}

export default new UbicazioniService();
